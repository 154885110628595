body {
  background: black linear-gradient(#08002A, #000000) no-repeat;
  margin: 0;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

.navbar {
  font-size: 30px;
  display: flex;
}

@media screen and (max-width: 1200px) {
  body{
    font-size: 1rem !important;
    letter-spacing: 0;
  }
}