.fs-logo{
    font-size: 1.8rem !important;
}

.nav-icon img{
    height: 100px;
}

.nav-icon {
    margin-left: 20px;
}

.credit-icons{
    text-align: right;
}

@media screen and (max-width: 1200px) {
    .nav-icon img{
        display: none;
    }
    .nav-icon {
        margin-left: 10px;
    }
    .credit-icons{
        padding: 0 !important;
    }
}