.icon img{
    max-height: 140px;
}

.fs-creditsText{
    color: rgba(255,255,255,0.8);
}

.creditsWindow{
    z-index: 1;
}

.fs-icon-container{
    font-size: 1.4rem !important;
    justify-content: space-between;
}

.fs-socials{
    margin-right: 8vh;
}

.fs-icons{
    margin-left: 10px;
    margin-right: 10px;
}

.col-version-bl{
    justify-content: flex-end;
    text-align: right;
    padding-right: 10vh;
    max-width: 40%;
}

.col-version{
    justify-content: flex-start;
    padding-left: 10vh;
    max-width: 60%;
}

.version{
    justify-content: space-between;
}

@media screen and (max-width: 1200px) {
    .version{
        justify-content: center;
        margin-top: 16px;
    }

    .col-version{
        justify-content: center;
        text-align: center;
        padding: 0px;
        max-width: 100%;
    }

    .col-version-bl{
        justify-content: center;
        text-align: center;
        padding: 0px;
        max-width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .icon img{
        max-height: 9vh;
    }
}