.fs-solid-icon{
    font-size: 50px;
    display: grid;
    place-items: center;
    margin-top: 30px;
}

.fs-survey-text{
    font-size: 1.2rem;
    padding-top: 8vh;
    padding-bottom: 3vh;
}

.fs-mainText{
    padding-top: 5%;
    color: rgba(255,255,255,0.8);
}

ul
{
    list-style-type: none;
}

a{
    color: #FFFFFF !important;
    text-decoration: none;
}

@media screen and (max-width: 1200px) {
    .fs-solid-icon{
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .fs-solid-icon{
        display: none;
    }
}