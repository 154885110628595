@import url(https://fonts.googleapis.com/css2?family=News+Cycle&display=swap);
body {
  background: black linear-gradient(#08002A, #000000) no-repeat;
  margin: 0;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

.navbar {
  font-size: 30px;
  display: flex;
}

@media screen and (max-width: 1200px) {
  body{
    font-size: 1rem !important;
    letter-spacing: 0;
  }
}
.fs-logo{
    font-size: 1.8rem !important;
}

.nav-icon img{
    height: 100px;
}

.nav-icon {
    margin-left: 20px;
}

.credit-icons{
    text-align: right;
}

@media screen and (max-width: 1200px) {
    .nav-icon img{
        display: none;
    }
    .nav-icon {
        margin-left: 10px;
    }
    .credit-icons{
        padding: 0 !important;
    }
}
.fs-solid-icon{
    font-size: 50px;
    display: grid;
    place-items: center;
    margin-top: 30px;
}

.fs-survey-text{
    font-size: 1.2rem;
    padding-top: 8vh;
    padding-bottom: 3vh;
}

.fs-mainText{
    padding-top: 5%;
    color: rgba(255,255,255,0.8);
}

ul
{
    list-style-type: none;
}

a{
    color: #FFFFFF !important;
    text-decoration: none;
}

@media screen and (max-width: 1200px) {
    .fs-solid-icon{
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .fs-solid-icon{
        display: none;
    }
}
*{
    font-family: 'News Cycle', sans-serif;
}

html {
    scroll-behavior: smooth;
    min-height:100vh;
}

a {
    outline: none;
}

#App, .App{
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#fs-main canvas {
    display: block;
    vertical-align: bottom;
    transform: scale(1);
    opacity: 1;
    transition: opacity .8s ease, transform 1.4s ease
}

#fs-main {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0
}

.line{
    clear: both;
    display: block;
    width: 96%;
    background-color:rgba(255,255,255,0.4);
    height: 1px;
}
.icon img{
    max-height: 140px;
}

.fs-creditsText{
    color: rgba(255,255,255,0.8);
}

.creditsWindow{
    z-index: 1;
}

.fs-icon-container{
    font-size: 1.4rem !important;
    justify-content: space-between;
}

.fs-socials{
    margin-right: 8vh;
}

.fs-icons{
    margin-left: 10px;
    margin-right: 10px;
}

.col-version-bl{
    justify-content: flex-end;
    text-align: right;
    padding-right: 10vh;
    max-width: 40%;
}

.col-version{
    justify-content: flex-start;
    padding-left: 10vh;
    max-width: 60%;
}

.version{
    justify-content: space-between;
}

@media screen and (max-width: 1200px) {
    .version{
        justify-content: center;
        margin-top: 16px;
    }

    .col-version{
        justify-content: center;
        text-align: center;
        padding: 0px;
        max-width: 100%;
    }

    .col-version-bl{
        justify-content: center;
        text-align: center;
        padding: 0px;
        max-width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .icon img{
        max-height: 9vh;
    }
}
.fs-sorryContent{
    display: none;
}

@media screen and (max-width: 900px) {
    .mobileInstruction{
        display: none !important;
    }
    .fs-sorryContent{
        display: grid;
    }
    .mobileMsg{
        color: rgba(255,255,255,0.8);
        padding: 3vh;
        margin-top: 10vh;
    }
}
