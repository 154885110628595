@import url('https://fonts.googleapis.com/css2?family=News+Cycle&display=swap');
*{
    font-family: 'News Cycle', sans-serif;
}

html {
    scroll-behavior: smooth;
    min-height:100vh;
}

a {
    outline: none;
}

#App, .App{
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#fs-main canvas {
    display: block;
    vertical-align: bottom;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition: opacity .8s ease, -webkit-transform 1.4s ease;
    transition: opacity .8s ease, transform 1.4s ease
}

#fs-main {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0
}

.line{
    clear: both;
    display: block;
    width: 96%;
    background-color:rgba(255,255,255,0.4);
    height: 1px;
}