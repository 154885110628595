.fs-sorryContent{
    display: none;
}

@media screen and (max-width: 900px) {
    .mobileInstruction{
        display: none !important;
    }
    .fs-sorryContent{
        display: grid;
    }
    .mobileMsg{
        color: rgba(255,255,255,0.8);
        padding: 3vh;
        margin-top: 10vh;
    }
}